import Breadcrumb from '../../components/breadcrumb/crumb';
import '../news/news.css';
import './dashboard.css';
import banner from '../../img/newsBanner.png';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { RiArrowRightSLine } from 'react-icons/ri';
import { HashLink } from 'react-router-hash-link';
import Admincard from '../../components/card/adminCard';
import Loader from '../../components/loading/loading';

const Dashboard = () => {
    const [newsData, setNewsData] = useState(null);
    const [selectedCat, setSelectedCat] = useState('All News');
    const [searchPhrase, setSearchPhrase] = useState('');
    const [loading, setLoading] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState(searchPhrase);
   /*  const limit = 9; */

    // Function to handle category selection
    const selectCategory = (event) => {
        setSelectedCat(event.target.value);
    };

    // Function to handle search input change
    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearchPhrase(e.target.value);

    };

    // Function to fetch search results from the backend API
    const searchFunction = async (searchQuery) => {
        if (searchQuery.trim() === '') {
            setNewsData([]);  // Clear results if the search is empty
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(`https://usenapi.onrender.com/blog/search`, {
                params: { query: searchQuery },
            });
            setNewsData(response.data.results);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setNewsData([]);
        } finally {
            setLoading(false);
        }
    };

    // Debouncing the search term to limit API calls
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearch(searchPhrase);
        }, 500);

        return () => clearTimeout(timeoutId);  // Cleanup the timeout on each change
    }, [searchPhrase]);

    // useEffect to perform the search when the debounced value changes
    useEffect(() => {
        if (debouncedSearch) {
            searchFunction(debouncedSearch);
        } else {
            setNewsData([]);  // Clear results if no search term
        }
    }, [debouncedSearch]);

    // Fetch data for the selected category on component mount
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (selectedCat === 'All News') {
                    const response = await axios.get(`https://usenapi.onrender.com/blog/latest-news?limit=1000`);
                    const data = await response.data;
                    setNewsData(data);
                } else {
                const response = await axios.get(`https://usenapi.onrender.com/blog/news/category/${selectedCat}`);
                const data = await response.data.posts;
                setNewsData(data); }
            } catch (error) {
                console.error('Error fetching news data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [selectedCat]);

    return (
        <>
            <div className="news container">
                <Breadcrumb />
                <div className="newsBanner">
                    <img src={banner} alt="page banner" className="newsBannerImg" />
                    <div className="newsBannerTxt">
                        <h3 className="bannertitle">UsenVoice TV Login</h3>
                    </div>
                </div>
                <div className="filter">
                    <p className="allNews">{selectedCat}</p>
                    <div className='filterParameters'>
                        <div className="selection">
                            <label htmlFor="select">Sort By:</label>
                            <select id="select" name="select" value={selectedCat} onChange={selectCategory}>
                                <option value="All News">All News</option>
                                <option value="News">News</option>
                                <option value="Entertainment">Entertainment</option>
                                <option value="Politics">Politics</option>
                                <option value="Business">Business</option>
                                <option value="National">National</option>
                                <option value="Sport">Sport</option>
                            </select>
                        </div>
                        <div className="searchBar">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <input type="text" placeholder="Search..." value={searchPhrase} onChange={handleSearchChange} />
                            </form>
                        </div>
                    </div>
                    <HashLink to='/dashboard/create-post'><button>Post News <RiArrowRightSLine  size={12}/></button></HashLink>
                </div>

                {/* Render the loading state, news data, or no results message */}
                <div className="newsList">
                    {loading ? (
                        <Loader />
                    ) : newsData && newsData.length > 0 ? (
                        newsData.map((item) => (
                            
                            <Admincard key={item._id} data={item}/>
                        ))
                    ) : (
                        <p>No news found.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Dashboard;
