import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Navbar from './components/nav/nav';
import Marquee from './components/maquee/maquee';
import Home from './pages/home/home';
import News from './pages/news/news';
import Business from './pages/news/business';
import Politics from './pages/news/politics';
import Entertainment from './pages/news/entertainment';
import National from './pages/news/national';
import Newsdetails from './pages/details/details';
import About from './pages/about/about';
import Topheadline from './pages/news/latest';
import Login from './pages/admin/admin-login';
import Dashboard from './pages/admin/dashboard';
import ProtectedRoute from './protected';
import Edit from './pages/admin/editpost/editpost';
import CreatePost from './pages/admin/createpost/createPost';
import Sport from './pages/news/sport';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Marquee />        
        <Routes>
          <Route index element={<Home/>} />
          <Route path='/dashboard' element={<ProtectedRoute/>}>
            <Route index element={<Dashboard/>}/>
            <Route path='edit' element={<Edit/>}/>
            <Route path='create-post' element={<CreatePost/>}/>
          </Route>
          <Route path="/Admin" element={<Login />} />
          <Route path='/News' element={<News />} />
          <Route path='/Business' element={<Business />} />
          <Route path='/Politics' element={<Politics />} />
          <Route path='/Entertainment' element={<Entertainment />} />
          <Route path='/Sport' element={<Sport />} />
          <Route path='/National' element={<National />} />
          <Route path='/:category/:title' element={<Newsdetails />} />
          <Route path='/About' element={<About />} />
          <Route path='/Topheadline' element={<Topheadline />} />
        </Routes>
      </BrowserRouter> 
      
    </div>
  );
}

export default App;
