import { RiArrowRightSLine } from 'react-icons/ri';
import Breadcrumb from '../../components/breadcrumb/crumb';
import './details.css';
import { useLocation } from 'react-router-dom';
import OtherNews from '../../components/other/oNews';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { BsCalendarEvent } from 'react-icons/bs';
import { CiFolderOn } from 'react-icons/ci';
import Loader from '../../components/loading/loading';

const Newsdetails = () => {
    const location = useLocation();
    const { id } = location.state || {};

    const [news, setNews] = useState(null);
    const [username, setUsername] = useState('');
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get(`https://usenapi.onrender.com/blog/news/${id}`);
                setNews(response.data);
            } catch (error) {
                console.error("Error fetching news:", error);
                setError("Failed to load news details. Please try again later.");
            }
        };

        fetchNews();
    }, [id]);

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`https://usenapi.onrender.com/blog/news/${id}/comments`, {
                username: username || "Anonymous",
                content: comment,
            });

            setNews((prevNews) => ({
                ...prevNews,
                comments: [...prevNews.comments, response.data],
            }));

            setUsername('');
            setComment('');
        } catch (error) {
            console.error("Error posting comment:", error);
        } finally {
            setLoading(false);
        }
    };

    if (error) return <p className="error">{error}</p>;
    if (!news) return <Loader />;

    return (
        <>
            <div className="newsDetails container">
                <Breadcrumb />
                <div className="detailsBanner">
                    <div>
                        <div className="detailsTitle">
                            <h4>{news.title}</h4>
                        </div>
                        <img src={news.thumb} alt="news" className="detailsImg" />
                        <div className="metaData">
                            <p className="metaDate">
                                <BsCalendarEvent size={12} style={{ marginRight: '2%' }} />
                                {news.date}
                            </p>
                            {/* <p className="metaCategory">
                                <CiFolderOn size={12} style={{ marginRight: '2%' }} />
                                Category: {news.category}
                            </p> */}
                        </div>
                    </div>
                </div>
                <pre className="detailsBody" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.body) }}></pre>

                {/* Comments Section */}
                <section className="commentsSection">
                    <h3>Comments</h3>
                    <ul className="commentsList">
                        {news.comments.map((comment, index) => (
                            <li key={index} className="comment">
                                <p className="username">
                                    @{comment.username}{' '}
                                    <span className="commentDate">
                                        {new Date(comment.createdAt).toLocaleDateString()}
                                    </span>
                                </p>
                                <p className="commentContent">{comment.content}</p>
                            </li>
                        ))}
                    </ul>

                    <form className="commentForm" onSubmit={handleCommentSubmit}>
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <textarea
                            placeholder="Your comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            required
                        />
                        <button type="submit" disabled={loading}>
                            {loading ? 'Posting...' : 'Post Comment'}
                        </button>
                    </form>
                </section>
            </div>

            <article className="otherNews container">
                <div className="headlines">
                    <div>
                        <span className="usenVoice">UsenVoice TV</span>
                        <p className="heading">Follow other news</p>
                    </div>
                </div>
                <OtherNews />
                <button>
                    View more <RiArrowRightSLine size={12} />
                </button>
            </article>

            <Footer />
        </>
    );
};

export default Newsdetails;
