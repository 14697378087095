import { useEffect, useState } from 'react';
import './oNews.css';
import { BsCalendarEvent } from 'react-icons/bs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Loader from '../loading/loading';

const OtherNews = () => {
    const Data = [
        { id: 0, name: 'Sport' },
        { id: 1, name: 'News' },
        { id: 3, name: 'Entertainment' },
        { id: 4, name: 'Politics' },
        { id: 5, name: 'Business' },
        { id: 6, name: 'National' },
    ];

    const [selectedId, setSelectedId] = useState(null);
    const [newsCat, setNewsCat] = useState('News');
    const [newsData, setNewsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const Active = (header) => {
        setSelectedId(header.id);
        setNewsCat(header.name);
        setNewsData([]); // Reset `newsData` when a new category is selected
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setNewsData([]); // Clear previous data before fetching new data
            try {
                const response = await axios.get(
                    `https://usenapi.onrender.com/blog/news/category/${newsCat}`
                );
                const data = response.data.posts || [];
                setNewsData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setNewsData([]); // Ensure `newsData` is empty if there’s an error
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [newsCat]);

    const navigate = useNavigate();

    // Handler to go to the details page
    const goToDetails = (id, category, title) => {
        navigate(`/${category}/${title}`, { state: { id } });
        window.location.reload();
    };

    return (
        <span className="oNews">
            <nav>
                <ul className="otherLinks">
                    {Data.map((header) => (
                        <li
                            key={header.id}
                            className={
                                header.id === selectedId
                                    ? 'oNewsLink oNewsLinkActive'
                                    : 'oNewsLink'
                            }
                            onClick={() => Active(header)}
                        >
                            {header.name}
                        </li>
                    ))}
                </ul>
            </nav>
            <section className="otherNewsCards">
                {isLoading ? (
                    <Loader />
                ) : newsData.length === 0 ? (
                    <p className="noNewsMessage">
                        No news available for this category yet.
                    </p>
                ) : (
                    newsData.map((data) => (
                        <div
                            key={data._id}
                            className="oNewsCard"
                            onClick={() =>
                                goToDetails(data._id, data.category, data.title)
                            }
                        >
                            <img
                                src={data.thumb}
                                alt="news"
                                className="oNewsCardImg"
                            />
                            <div className="oNewsCardTxt">
                                <p className="txtPri oNewsTitle">{data.title}</p>
                                <p
                                    className="oNewsSnippet"
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(data.body),
                                    }}
                                ></p>
                                <p className="oNewsDate">
                                    <BsCalendarEvent
                                        size={12}
                                        style={{ marginRight: '2%' }}
                                    />{' '}
                                    {data.date}
                                </p>
                            </div>
                        </div>
                    ))
                )}
            </section>
        </span>
    );
};

export default OtherNews;
